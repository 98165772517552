<template>
  <div>
    <title-bar :title="$t('navigations.contacts')"/>
    <div v-if="this.rows" class="custom-table">
      <table-actions
        :actions="['addBtn', 'perPage']"
        :addBtnText="$t('contact.add')"
        :addBtnLink="$helper.getAddUrl('contact')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          <router-link :to="$helper.getEditUrl('contact', data.value.id)">
            {{ data.value.givenName }} {{ data.value.familyName }}
          </router-link>
        </template>
        <template v-slot:email="data">
          {{ data.value.email }}
        </template>
        <template v-slot:telephone="data">
          {{ data.value.telephone }}
        </template>
        <template v-slot:note="data">
          {{ data.value.note }}
        </template>
        <template v-slot:createdAt="data">
          {{ $helper.formatDate(data.value.createdAt) }}
        </template>
        <template v-slot:source="data">
          <b-badge :variant="sourceClass(data.value.source)">
              {{ $helper.getEnumTranslation("contact_source", data.value.source, $i18n.locale) }}
          </b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('contact', data.value.id)"/>
            <delete-btn @pressDelete="deleteContact(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      sortable: {
        order: "createdAt",
        sort: "desc",
      },
      columns: [
        {
          label: this.$t('contact.name'),
          field: "name",
          sortable: true,
        },
        {
          label: this.$t('contact.email'),
          field: "email",
          sortable: true,
        },
        {
          label: this.$t('contact.telephone'),
          field: "telephone",
          sortable: false,
        },
        {
          label: this.$t('contact.note'),
          field: "note",
          sortable: false,
        },
        {
          label: this.$t('contact.source'),
          field: "source",
          sortable: true,
        },
        {
          label: this.$t('contact.createdAt'),
          field: "createdAt",
          sortable: true,
        },
        {
          label: this.$t('contact.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadContacts();
  },
  methods: {
    loadContacts() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
        params["order[" + this.sortable.order + "]"] = this.sortable.sort;
        if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Contacts.getCollection({params}, 'contact_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadContacts();
    },
    sourceClass(source) {
      let className = 'success'
      if ('CONTACT_FORM' === source) {
        className = 'active'
      } else if ('FAQ_FORM' === source) {
        className = 'inactive'
      } else if ('AEROTAXI_FORM' === source) {
        className = 'not-published'
      } else if ('FLIGHT_FORM' === source) {
        className = 'closed'
      } else if ('SENT_MEMO' === source) {
        className = 'unsold'
      } else if ('ORDER' === source) {
        className = 'sold'
      } else if ('VOUCHER_ACTIVATION' === source) {
        className = 'invoiced'
      }
      return className
    },
    setSort(offset, limit, order, sort) {
      this.sortable.sort = sort;
      this.sortable.order = order;

      if (order === 'name') {
          this.sortable.order = 'givenName'
      }

      this.loadContacts();
    },
    deleteContact(url) {
      this.deleteByUrl(this.$Contacts, url, this.$t('contact.deleted'), null, this.loadContacts, this.error);
    },
    error() {
      this.$emit("clearAction");
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadContacts();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadContacts();
    },
    onPageChange() {
      this.loadContacts();
    },

  },
};
</script>

